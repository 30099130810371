<template>
  <div class="submitSuccess">
    <div class="bgc" />
    <el-result icon="success" title="提交成功">
      <template slot="subTitle">
        <div class="subTitle">请用PC端打开 {{ url }} 进行登录学习</div>
        <div class="prompt">（初次请使用验证码登陆）</div>
      </template>
      <template slot="extra">
        <el-button round type="primary" size="medium" @click="copyUrl">复制网址</el-button>
      </template>
    </el-result>
  </div>
</template>

<script>
import { copy } from '@/utils/system'

export default {
  name: 'SubmitSuccess',
  data() {
    return {
      url: ''
    }
  },
  created() {
    this.url = process.env.VUE_APP_PC_URL || 'http://stu.myhope365.com/'
  },
  methods: {
    copyUrl() {
      copy(this.url).then(() => {
        this.$message.success('复制成功')
      })
    }
  }
}
</script>

<style scoped lang="less">
.submitSuccess {
  .bgc {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: -1;
  }

  .subTitle {
    font-size: 3.2vw;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 15px;
  }

  .prompt {
    font-size: 3.2vw;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4392FD;
    line-height: 15px;
    margin-top: 2vh;
  }
}
</style>
